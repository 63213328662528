<template>
  <DashboardAuthLayout>
    <template #header>
      <img src="@/assets/images/careercolor.svg" alt="Logo" />
    </template>

    <div class="my-9 mt-8 p-4 md:p-[25px] bg-white rounded-xl w-full">
      <!-- SUCCESS DIALOG -->
      <Dialog v-show="displaySuccessDialog" class="backdrop-blur-sm">
        <div class="my-6 bg-white rounded- w-full">
          <div class="flex justify-between px-6">
            <div class="w-full">
              <div class="flex justify-center">
                <SuccessSvg />
              </div>
              <div class="pt-4">
                <h2 class="font-semibold text-brand-black leading-[125%] text-center text-[32px]">
                  Your account has been <br />
                  successfully created
                </h2>
                <p class="my-6 text-[16px] text-light-gray text-center">
                  Your account has been created, complete your <br />
                  profile to apply for jobs and get noticed.
                </p>
              </div>
              <div class="text-center pt-2">
                <button @click="proceed" class="w-full bg-brand-black text-white p-4 rounded-xl font-semibold">Go to Dashboard</button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <div>
        <h1 class="text-brand-black text-[28px] font-semibold leading-[130%]">Verify your email Address</h1>
        <div class="flex items-center mb-2">
          <span class="font-normal text-light-gray mr-3 text-[14px]">We’ve just sent a verification code to {{ userEmail.email }}</span>
        </div>

        <!-- <div class="mt-2">
          <vue-countdown :time="secondsToExpire" v-slot="{ minutes, seconds }" @end="otpExpired">
            OTP code expires in - {{ minutes }}m : {{ seconds }}s
          </vue-countdown>
        </div> -->
      </div>
      <div class="mt-2">
        <p>Not the correct email? <span class="underline cursor-pointer" @click="goBack">Edit</span></p>
      </div>

      <div>
        <div class="pt-4 space-x-2 md:space-x-4">
          <div style="display: flex; justify-content: center; align-items: center">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
              :placeholder="['*', '*', '*', '*', '*', '*']"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
            />
          </div>
        </div>
        <!-- <div class="pt-4 space-x-2 md:space-x-4">
          <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
            <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError"> </VueRecaptcha>
          </div>
        </div> -->
        <div class="pt-4 space-x-2 md:space-x-4">
          <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
            <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError"> </VueRecaptcha>
          </div>
        </div>

        <div class="pt-4">
          <button
            class="w-full bg-brand-black text-white p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
            @click="handleRegistration"
          >
            Verify <ButtonLoader :loading="loading" />
          </button>
        </div>
        <p class="text-center mt-6 text-[#8F8F8F]">
          <resend-otp v-if="canResendOtp" @resend="triggerRequestOtp" />
          <vue-countdown v-if="!canResendOtp" :time="secondsToResend" v-slot="{ minutes, seconds }" @end="canResendOtp = true">
            Resend code in {{ minutes }}:{{ seconds }}
          </vue-countdown>
        </p>
        <!-- <div class="pt-4">
          <div class="flex items-center">
            <span class="font-normal text-light-gray mr-3 text-[16px]">Already have an account? </span>
            <router-link to="/login" class="text-brand-black font-medium text-[16px]">Log in</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </DashboardAuthLayout>
</template>

<script setup>
import SuccessSvg from '@/assets/icons/success-dark.svg?inline';
import DashboardAuthLayout from '@/layouts/AuthLayout.vue';
import CircleProgress from '@/components/CircleProgress.vue';
import ButtonLoader from '@/components/ButtonLoader.vue';
import Dialog from '@/components/Dialog.vue';
import ResendOtp from '@/components/ResendOtp.vue';
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import { VueRecaptcha } from 'vue-recaptcha';

const displaySuccessDialog = ref(false);
const router = useRouter();
const store = useStore();
const otpInput = ref(null);
const otp = ref('');
const loading = ref(false);
const canResendOtp = ref(false);
const secondsToExpire = ref(580990);
const secondsToResend = ref(Math.round(1.2 * 100000));
const captcha = ref('');
const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_KEY);
const userEmail = ref(JSON.parse(localStorage.getItem('details')));

const handleError = () => {};

const handleSuccess = (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(response);
  }
  captcha.value = response;
};

const handleOnComplete = (value) => {
  otp.value = value;
};

const goBack = () => {
  router.push('/register');
};

function showSuccessDialog() {
  displaySuccessDialog.value = true;
}

async function handleRegistration() {
  try {
    loading.value = true;
    await store.dispatch('auth/verifyOtp', {
      otp: `${otp.value}`,
      captcha: captcha.value,
    });
    showSuccessDialog();
    localStorage.removeItem('details');
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
}

function proceed() {
  const registrationType = localStorage.getItem('registrationType');

  //router.push("/settings");
  router.push('/profile-setup');
}

async function otpExpired() {
  secondsToExpire.value = 0;
  alert('Your otp code has expired, use the resend link to get a new one', 'info', 5000);
  //await requestOtp();
  //secondsToExpire.value = 580990;
}

const triggerRequestOtp = () => {
  canResendOtp.value = false;
  secondsToResend.value = Math.round(secondsToResend.value * 1.7);
  requestOtp();
  secondsToExpire.value = 580990;
};

const requestOtp = async () => {
  try {
    await store.dispatch('auth/requestOtp');
    alert('A new OTP has been sent to your mail', 'success');
  } catch (error) {
    alert(errorMessage(error) || 'Something went wrong (Please check internent connection)', 'error', 4000);
  }
};

onMounted(() => {
  // console.log(userEmail);
});
</script>

<style>
.otp-input {
  width: 60px !important;
  height: 45px !important;
  padding: 5px !important;
  margin: 20px 10px 0px 10px !important;
  font-size: 20px !important;
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  text-align: center !important;
}
.otp-input:focus {
  background: #fef6f8;
  border: 1px solid #f0386a;
  border-radius: 5px;
}

@media screen and (max-width: 900px) {
  .otp-input {
    width: 40px !important;
    height: 40px !important;
    flex-wrap: wrap;
  }
  .otp-input:focus {
    background: #fef6f8;
    border: 1px solid #f0386a;
    border-radius: 5px;
  }
}
</style>
