<template>
  <div class="flex w-full min-h-screen">
    <div class="w-2/5 min-h-screen bg-cover hidden lg:block" :style="{ backgroundImage: `url(${require('@/assets/images/1.png')})` }"></div>

    <div class="grow grid lg:content-center bg-cover" :style="{ backgroundImage: `url(${require('@/assets/images/NavauthBg.png')})` }">
      <div class="w-full md:max-w-[800px] lg:pl-[109px] md:px-8 px-6 pt-10 md:pt-0">
        <img src="@/assets/images/logo-new.svg" class="w-[230px] mt-4" alt="" />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
